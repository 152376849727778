import { Trans, useTranslation } from 'next-i18next'

import { DiscordFilled, MirrorFilled, TwitterFilled } from '@/icons'

import { NavBarLogo } from './navbar'
import Link from './TwLink'

const Footer = () => {
  const { t } = useTranslation()
  const socials = [
    {
      title: 'Twitter',
      icon: TwitterFilled,
      href: 'https://twitter.com/the_x2y2',
    },
    {
      title: 'Discord',
      icon: DiscordFilled,
      href: 'https://discord.gg/dmJAQ5tRF5',
    },
    {
      title: 'Mirror',
      icon: MirrorFilled,
      href: 'https://mirror.xyz/x2y2.eth',
    },
  ]
  const links = [
    {
      title: t('Contact Us'),
      href: 'https://form.nativeforms.com/x2y2-collaboration',
    },
    {
      title: t('Docs'),
      href: 'https://docs.x2y2.io/',
    },
    {
      title: t('Audit Report'),
      href: 'https://docs.x2y2.io/assets/files/X2Y2_Protocol_Report-93b524ab7d8e9a000efcfeec12fc4aa4.pdf',
    },
    {
      title: t('Brand'),
      href: 'https://docs.x2y2.io/about/brands',
    },
    {
      title: t('Terms'),
      href: '/page/terms',
    },
    {
      title: t('Privacy'),
      href: '/page/privacy',
    },
  ]

  return (
    <footer className="flex flex-col space-y-10 border-t px-6 py-10 lg:flex-row lg:justify-between lg:space-y-0 lg:p-16">
      <div className="space-y-2">
        <div className="flex items-center">
          <NavBarLogo className="h-6 w-auto" />
          <p className="ts-body-3 ml-auto text-gray-500 lg:ml-6">
            {t('© 2021 - 2024 X2Y2')}
          </p>
        </div>
        <p className="ts-body-3 text-gray-500">
          <Trans t={t}>
            <span className="text-gray-700">100% revenue shared</span> with X2Y2
            token stakers.
          </Trans>
        </p>
      </div>
      <div className="flex flex-col space-y-2 lg:items-end">
        <div className="flex space-x-6 py-1 lg:space-x-8">
          {socials.map((a, i) => (
            <Link
              className="flex"
              hoverStyle="opacity"
              aria-label={a.title}
              href={a.href}
              isExternal
              key={i}
            >
              <a.icon className="h-5 w-5" />
            </Link>
          ))}
        </div>
        <div className="flex flex-wrap gap-x-3 gap-y-2 lg:gap-x-5">
          {links.map((a, i) => (
            <Link
              className="ts-body-3 text-gray-500"
              href={a.href}
              isExternal={a.href.startsWith('http')}
              key={i}
            >
              {a.title}
            </Link>
          ))}
        </div>
      </div>
    </footer>
  )
}

export default Footer
